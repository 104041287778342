/* Add your variable customizations of theme here */
@import '../../../css/variables';

$infoMessageBgColor: $infoMessageColor;
$infoMessageTextColor: #212121;
$warnMessageBgColor: $warnMessageColor;
$warnMessageTextColor: #212121;
$errorMessageBgColor: $errorMessageColor;
$errorMessageTextColor: #212121;
$successMessageBgColor: $successMessageColor;
$successMessageTextColor: #212121;
