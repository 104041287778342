$primaryColor: #26337c;
$primaryDarkColor: #404a80;
$primaryLightColor: #90a4ae;
$primaryLightestColor: #eceff1;
$primaryTextColor: #ffffff;
$accentTextColor: #ffffff;
$textColor: #5c5f6a;

$highlightTableRowColor: rgba(0, 0, 0, 0.3);

$successMessageColor: #00cc66;
$infoMessageColor: #006cbd;
$errorMessageColor: #ff1432;
$warnMessageColor: #ffc419;
$messageTextColor: black;

$activeColor: #00cc66;
$inactiveColor: #ff1432;
$waitingColor: #ffc419;
