/* Add your customizations of the theme here */
body {
  .p-picklist {
    width: 100%;

    &.p-picklist-custom-responsive {
      * {
        box-sizing: border-box;
      }

      .p-picklist-buttons {
        width: 3.429em;

        .p-button {
          &.p-button-icon-only {
            margin: 0 auto;
            display: block;
            margin-bottom: 0.571em;

          }
        }
      }

      .p-picklist-list {
        width: 100%;

        .p-picklist-item {
          .p-checkbox {
            margin-right: 0.571em;
            vertical-align: top;

          }
          .p-checkbox, .p-checkbox * {
            box-sizing: content-box;

          }
        }
      }

      .p-picklist-target-controls{
        .p-picklist-buttons {
          padding-top: .4em;
        }
      }

      &.vertical-layout {
        display: block;

        > div {
          display: block;
          width: 100% !important;
        }

        .p-picklist-list-wrapper {
          margin-bottom: .5em;
        }

        .p-picklist-buttons {
          text-align: center;
          height: auto;
          padding: .5em 0;

          .p-button {
            &.p-button-icon-only {
              display: inline-block;
              margin-right: .25em;
              margin-bottom: 0;
            }
          }

          .pi-angle-right {
            @include icon-override("play_arrow");
            @include rotate(90deg);
          }
          .pi-angle-double-right {
            @include icon-override("skip_previous");
            @include rotate(-90deg);

          }
          .pi-angle-left {
            @include icon-override("play_arrow");
            @include rotate(-90deg);
          }
          .pi-angle-double-left {
            @include icon-override("skip_next");
            @include rotate(-90deg);
          }
        }
      }
    }
  }
}