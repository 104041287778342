@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin multi-shadow($value1, $value2, $value3) {
    -webkit-box-shadow: $value1, $value2, $value3;
    -moz-box-shadow: $value1, $value2, $value3;
    box-shadow: $value1, $value2, $value3;
}

@mixin content-shadow() {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12)
}

@mixin overlay-shadow() {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

@mixin overlay-content-shadow() {
    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

@mixin overlay-input-shadow() {
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin hover-element {
    background-color: $hoverBgColor;
    color: $hoverTextColor;
}

@mixin hover-element-primary {
    background-color: $primaryLightColor;
    color: $hoverTextColor;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

@mixin border-box-sizing() {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@mixin material-icon($icon) {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: $iconFontSize;  /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';

    &:before {
        content: $icon;
    }
}

@mixin icon-override($icon) {
    @include material-icon($icon);
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin rippleitem() {
    position: relative;
    overflow: hidden;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model;
    -moz-box-sizing: $box-model;
    box-sizing: $box-model;
}

@mixin animation($delay, $duration, $animation) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@mixin background-gradient-top2bottom($start-color, $end-color) {
    background-color: $start-color;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(top, $start-color, $end-color);
    background-image:    -moz-linear-gradient(top, $start-color, $end-color);
    background-image:     -ms-linear-gradient(top, $start-color, $end-color);
    background-image:      -o-linear-gradient(top, $start-color, $end-color);
    background-image:         linear-gradient(to bottom, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$start-color}', endColorStr='#{$end-color}');
}

@mixin raised-btn() {
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
}

@mixin flex() {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin content() {
    background-color: $contentBgColor;
    border: 1px solid $contentBorderColor;
}

@mixin header() {
    background-color: $primaryColor;
    color: $primaryTextColor;
    border: 1px solid $primaryColor;
}

@mixin corner-all {
    @include border-radius($borderRadius);
}

@mixin corner-top {
    @include border-radius-top($borderRadius);
}

@mixin corner-bottom {
    @include border-radius-bottom($borderRadius);
}

@mixin corner-left {
    @include border-radius-left($borderRadius);
}

@mixin corner-right {
    @include border-radius-right($borderRadius);
}