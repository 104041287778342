@import '../variables';

.date-col {
    width: 150px;
}

.title-col,
.description-col {
    width: 200px;
}

.participants-col,
.state-col {
    width: 50px;
}

.icon {
    &.open {
        color: $activeColor !important;
    }

    &.ongoing {
        color: $activeColor !important;
        animation: blinker 1.2s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

        @keyframes blinker {
            from {
                opacity: 1;
            }
            to {
                opacity: 0.2;
            }
        }
    }

    &.completed {
        color: $waitingColor !important;
    }

    &.archived {
        color: $inactiveColor !important;
    }

    &.deleted {
        color: black;
    }
}

.add-participant-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &.hasParticipants {
        padding-top: 10px;
    }
}

.edit-metadata-input-wrapper {
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    .edit-metadata-input-group {
        //margin-right from p-button class
        margin-right: 0.25em;

        padding-bottom: 14px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .edit-metadata-inputs {
            width: 100%;

            .metadata-input {
                input {
                    width: 95% !important;
                }

                &:last-child {
                    padding-left: unset;
                }

                &:not(:last-child) {
                    padding-right: unset;
                }
            }
        }
    }

    .add-metadata-button-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .delete-metadata-button {
        background-color: $inactiveColor !important;
    }
}
