.pi {
	&.pi-arrow-circle-right {@include icon-override("play_circle_outline"); }
	&.pi-arrow-circle-left {@include icon-override("play_circle_outline"); @include rotate(180deg);}
	&.pi-arrow-down{ @include icon-override("arrow_downward"); }
	&.pi-arrow-up { @include icon-override("arrow_upward"); }
	&.pi-angle-up {@include icon-override("play_arrow"); @include rotate(-90deg);}
	&.pi-angle-double-up { @include icon-override("skip_next"); @include rotate(-90deg);}
	&.pi-angle-down {@include icon-override("play_arrow"); @include rotate(90deg);}
	&.pi-angle-double-down {@include icon-override("skip_previous"); @include rotate(-90deg);}
	&.pi-angle-right {@include icon-override("play_arrow");}
	&.pi-angle-double-right {@include icon-override("fast_forward");}
	&.pi-angle-left {@include icon-override("play_arrow"); @include rotate(180deg);}
	&.pi-angle-double-left {@include icon-override("fast_rewind");}
	&.pi-calendar { @include icon-override("date_range"); }
	&.pi-caret-up {@include icon-override("keyboard_arrow_up");}
	&.pi-caret-down {@include icon-override("keyboard_arrow_down");}
	&.pi-caret-right {@include icon-override("keyboard_arrow_right");}
	&.pi-caret-left {@include icon-override("keyboard_arrow_left");}
	&.pi-check {@include icon-override("check");}
	&.pi-chevron-circle-left {@include icon-override("keyboard_arrow_left");}
	&.pi-chevron-circle-right {@include icon-override("keyboard_arrow_right");}
	&.pi-chevron-circle-down {@include icon-override("keyboard_arrow_down");}
	&.pi-chevron-circle-up {@include icon-override("keyboard_arrow_up");}
	&.pi-chevron-right {@include icon-override("chevron_right");}
	&.pi-chevron-left {@include icon-override("chevron_left");}
	&.pi-chevron-down {@include icon-override("keyboard_arrow_down");}
	&.pi-chevron-up {@include icon-override("keyboard_arrow_up");}
	&.pi-circle-off{@include icon-override("radio_button_unchecked");}
	&.pi-circle-on{@include icon-override("radio_button_checked");}
	&.pi-minus {@include icon-override("remove");}
	&.pi-plus {@include icon-override("add");}
	&.pi-info-circle {@include icon-override("info");}
	&.pi-upload  {@include icon-override("file_upload");}
	&.pi-home {@include icon-override("home");}
	&.pi-times {@include icon-override("close");}
	&.pi-search {@include icon-override("search");}
	&.pi-sort {@include icon-override("sort");}
	&.pi-sort-up {@include icon-override("keyboard_arrow_up");}
	&.pi-sort-down {@include icon-override("keyboard_arrow_down");}
	&.pi-th-large {@include icon-override("grid_on");}
	&.pi-bars {@include icon-override("list");}
}

.#{$iconPrefix}-carat-2-n-s {@include icon-override("sort");}
.#{$iconPrefix}-triangle-1-n { @include icon-override("keyboard_arrow_up"); }
.#{$iconPrefix}-triangle-1-e { @include icon-override("keyboard_arrow_right"); }
.#{$iconPrefix}-triangle-1-s { @include icon-override("keyboard_arrow_down"); }
.#{$iconPrefix}-triangle-1-w { @include icon-override("keyboard_arrow_left"); }
.#{$iconPrefix}-carat-1-n { @include icon-override("keyboard_arrow_up"); }
.#{$iconPrefix}-carat-1-e { @include icon-override("keyboard_arrow_right"); }
.#{$iconPrefix}-carat-1-s { @include icon-override("keyboard_arrow_down"); }
.#{$iconPrefix}-carat-1-w { @include icon-override("keyboard_arrow_left"); }
.#{$iconPrefix}-arrow-1-n { @include icon-override("play_arrow"); @include rotate(-90deg);}
.#{$iconPrefix}-arrowstop-1-n { @include icon-override("skip_next"); @include rotate(-90deg);}
.#{$iconPrefix}-arrow-1-s { @include icon-override("play_arrow"); @include rotate(90deg);}
.#{$iconPrefix}-arrowstop-1-s { @include icon-override("skip_previous"); @include rotate(-90deg);}
.#{$iconPrefix}-arrow-1-w { @include icon-override("play_arrow"); @include rotate(180deg);}
.#{$iconPrefix}-arrowstop-1-w { @include icon-override("fast_rewind"); }
.#{$iconPrefix}-arrow-1-e { @include icon-override("play_arrow"); }
.#{$iconPrefix}-arrowstop-1-e { @include icon-override("fast_forward"); }
.#{$iconPrefix}-arrowthick-1-s { @include icon-override("play_arrow"); @include rotate(90deg);}
.#{$iconPrefix}-arrowthick-1-n { @include icon-override("play_arrow"); @include rotate(-90deg);}
.#{$iconPrefix}-circle-triangle-e {@include icon-override("play_circle_outline"); }
.#{$iconPrefix}-circle-triangle-w {@include icon-override("play_circle_outline"); @include rotate(180deg);}
.#{$iconPrefix}-circle-triangle-s {@include icon-override("play_circle_outline"); @include rotate(90deg);}
.#{$iconPrefix}-radio-off {@include icon-override("radio_button_unchecked");}
.#{$iconPrefix}-radio-on {@include icon-override("radio_button_checked");}
.#{$iconPrefix}-folder-collapsed {@include icon-override("folder");}
.#{$iconPrefix}-document {@include icon-override("content_paste");}
.#{$iconPrefix}-video {@include icon-override("movie");}
.#{$iconPrefix}-music {@include icon-override("music_note");}
.#{$iconPrefix}-plus {@include icon-override("add");}
.#{$iconPrefix}-minus {@include icon-override("remove");}
.#{$iconPrefix}-plusthick {@include icon-override("add");}
.#{$iconPrefix}-minusthick {@include icon-override("remove");}
.#{$iconPrefix}-pencil {@include icon-override("edit");}
.#{$iconPrefix}-closethick { @include icon-override("close"); }
.#{$iconPrefix}-circle-close { @include icon-override("cancel"); }
.#{$iconPrefix}-gear { @include icon-override("settings"); }
.#{$iconPrefix}-calendar { @include icon-override("date_range"); }
.#{$iconPrefix}-trash { @include icon-override("delete_forever"); }
.#{$iconPrefix}-notice { @include icon-override("error_outline"); }
.#{$iconPrefix}-alert { @include icon-override("warning"); }
.#{$iconPrefix}-circle-zoomin { @include icon-override("zoom_in"); }
.#{$iconPrefix}-circle-zoomout { @include icon-override("zoom_out"); }
.#{$iconPrefix}-circle-arrow-e { @include icon-override("play_circle_outline"); }
.#{$iconPrefix}-circle-arrow-w { @include icon-override("play_circle_outline"); @include rotate(180deg); }

.#{$iconPrefix}-3d-rotation { @include icon-override("3d_rotation"); }
.#{$iconPrefix}-ac-unit { @include icon-override("ac_unit"); }
.#{$iconPrefix}-access-alarm { @include icon-override("access_alarm"); }
.#{$iconPrefix}-access-alarms { @include icon-override("access_alarms"); }
.#{$iconPrefix}-access-time { @include icon-override("access_time"); }
.#{$iconPrefix}-accessibility { @include icon-override("accessibility"); }
.#{$iconPrefix}-accessible { @include icon-override("accessible"); }
.#{$iconPrefix}-account-balance { @include icon-override("account_balance"); }
.#{$iconPrefix}-account-balance-wallet { @include icon-override("account_balance_wallet"); }
.#{$iconPrefix}-account-box { @include icon-override("account_box"); }
.#{$iconPrefix}-account-circle { @include icon-override("account_circle"); }
.#{$iconPrefix}-add { @include icon-override("add"); }
.#{$iconPrefix}-add-a-photo { @include icon-override("add_a_photo"); }
.#{$iconPrefix}-add-alarm { @include icon-override("add_alarm"); }
.#{$iconPrefix}-add-alert { @include icon-override("add_alert"); }
.#{$iconPrefix}-add-box { @include icon-override("add_box"); }
.#{$iconPrefix}-add-circle { @include icon-override("add_circle"); }
.#{$iconPrefix}-add-circle-outline { @include icon-override("add_circle_outline"); }
.#{$iconPrefix}-add-location { @include icon-override("add_location"); }
.#{$iconPrefix}-add-shopping-cart { @include icon-override("add_shopping_cart"); }
.#{$iconPrefix}-add-to-photos { @include icon-override("add_to_photos"); }
.#{$iconPrefix}-add-to-queue { @include icon-override("add_to_queue"); }
.#{$iconPrefix}-adjust { @include icon-override("adjust"); }
.#{$iconPrefix}-airline-seat-flat { @include icon-override("airline_seat_flat"); }
.#{$iconPrefix}-airline-seat-flat-angled { @include icon-override("airline_seat_flat_angled"); }
.#{$iconPrefix}-airline-seat-individual-suite { @include icon-override("airline_seat_individual_suite"); }
.#{$iconPrefix}-airline-seat-legroom-extra { @include icon-override("airline_seat_legroom_extra"); }
.#{$iconPrefix}-airline-seat-legroom-normal { @include icon-override("airline_seat_legroom_normal"); }
.#{$iconPrefix}-airline-seat-legroom-reduced { @include icon-override("airline_seat_legroom_reduced"); }
.#{$iconPrefix}-airline-seat-recline-extra { @include icon-override("airline_seat_recline_extra"); }
.#{$iconPrefix}-airline-seat-recline-normal { @include icon-override("airline_seat_recline_normal"); }
.#{$iconPrefix}-airplanemode-active { @include icon-override("airplanemode_active"); }
.#{$iconPrefix}-airplanemode-inactive { @include icon-override("airplanemode_inactive"); }
.#{$iconPrefix}-airplay { @include icon-override("airplay"); }
.#{$iconPrefix}-airport-shuttle { @include icon-override("airport_shuttle"); }
.#{$iconPrefix}-alarm { @include icon-override("alarm"); }
.#{$iconPrefix}-alarm-add { @include icon-override("alarm_add"); }
.#{$iconPrefix}-alarm-off { @include icon-override("alarm_off"); }
.#{$iconPrefix}-alarm-on { @include icon-override("alarm_on"); }
.#{$iconPrefix}-album { @include icon-override("album"); }
.#{$iconPrefix}-all-inclusive { @include icon-override("all_inclusive"); }
.#{$iconPrefix}-all-out { @include icon-override("all_out"); }
.#{$iconPrefix}-android { @include icon-override("android"); }
.#{$iconPrefix}-announcement { @include icon-override("announcement"); }
.#{$iconPrefix}-apps { @include icon-override("apps"); }
.#{$iconPrefix}-archive { @include icon-override("archive"); }
.#{$iconPrefix}-arrow-back { @include icon-override("arrow_back"); }
.#{$iconPrefix}-arrow-downward { @include icon-override("arrow_downward"); }
.#{$iconPrefix}-arrow-drop-down { @include icon-override("arrow_drop_down"); }
.#{$iconPrefix}-arrow-drop-down-circle { @include icon-override("arrow_drop_down_circle"); }
.#{$iconPrefix}-arrow-drop-up { @include icon-override("arrow_drop_up"); }
.#{$iconPrefix}-arrow-forward { @include icon-override("arrow_forward"); }
.#{$iconPrefix}-arrow-upward { @include icon-override("arrow_upward"); }
.#{$iconPrefix}-art-track { @include icon-override("art_track"); }
.#{$iconPrefix}-aspect-ratio { @include icon-override("aspect_ratio"); }
.#{$iconPrefix}-assessment { @include icon-override("assessment"); }
.#{$iconPrefix}-assignment { @include icon-override("assignment"); }
.#{$iconPrefix}-assignment-ind { @include icon-override("assignment_ind"); }
.#{$iconPrefix}-assignment-late { @include icon-override("assignment_late"); }
.#{$iconPrefix}-assignment-return { @include icon-override("assignment_return"); }
.#{$iconPrefix}-assignment-returned { @include icon-override("assignment_returned"); }
.#{$iconPrefix}-assignment-turned-in { @include icon-override("assignment_turned_in"); }
.#{$iconPrefix}-assistant { @include icon-override("assistant"); }
.#{$iconPrefix}-assistant-photo { @include icon-override("assistant_photo"); }
.#{$iconPrefix}-attach-file { @include icon-override("attach_file"); }
.#{$iconPrefix}-attach-money { @include icon-override("attach_money"); }
.#{$iconPrefix}-attachment { @include icon-override("attachment"); }
.#{$iconPrefix}-audiotrack { @include icon-override("audiotrack"); }
.#{$iconPrefix}-autorenew { @include icon-override("autorenew"); }
.#{$iconPrefix}-av-timer { @include icon-override("av_timer"); }
.#{$iconPrefix}-backspace { @include icon-override("backspace"); }
.#{$iconPrefix}-backup { @include icon-override("backup"); }
.#{$iconPrefix}-battery-alert { @include icon-override("battery_alert"); }
.#{$iconPrefix}-battery-charging-full { @include icon-override("battery_charging_full"); }
.#{$iconPrefix}-battery-full { @include icon-override("battery_full"); }
.#{$iconPrefix}-battery-std { @include icon-override("battery_std"); }
.#{$iconPrefix}-battery-unknown { @include icon-override("battery_unknown"); }
.#{$iconPrefix}-beach-access { @include icon-override("beach_access"); }
.#{$iconPrefix}-beenhere { @include icon-override("beenhere"); }
.#{$iconPrefix}-block { @include icon-override("block"); }
.#{$iconPrefix}-bluetooth { @include icon-override("bluetooth"); }
.#{$iconPrefix}-bluetooth-audio { @include icon-override("bluetooth_audio"); }
.#{$iconPrefix}-bluetooth-connected { @include icon-override("bluetooth_connected"); }
.#{$iconPrefix}-bluetooth-disabled { @include icon-override("bluetooth_disabled"); }
.#{$iconPrefix}-bluetooth-searching { @include icon-override("bluetooth_searching"); }
.#{$iconPrefix}-blur-circular { @include icon-override("blur_circular"); }
.#{$iconPrefix}-blur-linear { @include icon-override("blur_linear"); }
.#{$iconPrefix}-blur-off { @include icon-override("blur_off"); }
.#{$iconPrefix}-blur-on { @include icon-override("blur_on"); }
.#{$iconPrefix}-book { @include icon-override("book"); }
.#{$iconPrefix}-bookmark { @include icon-override("bookmark"); }
.#{$iconPrefix}-bookmark-border { @include icon-override("bookmark_border"); }
.#{$iconPrefix}-border-all { @include icon-override("border_all"); }
.#{$iconPrefix}-border-bottom { @include icon-override("border_bottom"); }
.#{$iconPrefix}-border-clear { @include icon-override("border_clear"); }
.#{$iconPrefix}-border-color { @include icon-override("border_color"); }
.#{$iconPrefix}-border-horizontal { @include icon-override("border_horizontal"); }
.#{$iconPrefix}-border-inner { @include icon-override("border_inner"); }
.#{$iconPrefix}-border-left { @include icon-override("border_left"); }
.#{$iconPrefix}-border-outer { @include icon-override("border_outer"); }
.#{$iconPrefix}-border-right { @include icon-override("border_right"); }
.#{$iconPrefix}-border-style { @include icon-override("border_style"); }
.#{$iconPrefix}-border-top { @include icon-override("border_top"); }
.#{$iconPrefix}-border-vertical { @include icon-override("border_vertical"); }
.#{$iconPrefix}-branding-watermark { @include icon-override("branding_watermark"); }
.#{$iconPrefix}-brightness-1 { @include icon-override("brightness_1"); }
.#{$iconPrefix}-brightness-2 { @include icon-override("brightness_2"); }
.#{$iconPrefix}-brightness-3 { @include icon-override("brightness_3"); }
.#{$iconPrefix}-brightness-4 { @include icon-override("brightness_4"); }
.#{$iconPrefix}-brightness-5 { @include icon-override("brightness_5"); }
.#{$iconPrefix}-brightness-6 { @include icon-override("brightness_6"); }
.#{$iconPrefix}-brightness-7 { @include icon-override("brightness_7"); }
.#{$iconPrefix}-brightness-auto { @include icon-override("brightness_auto"); }
.#{$iconPrefix}-brightness-high { @include icon-override("brightness_high"); }
.#{$iconPrefix}-brightness-low { @include icon-override("brightness_low"); }
.#{$iconPrefix}-brightness-medium { @include icon-override("brightness_medium"); }
.#{$iconPrefix}-broken-image { @include icon-override("broken_image"); }
.#{$iconPrefix}-brush { @include icon-override("brush"); }
.#{$iconPrefix}-bubble-chart { @include icon-override("bubble_chart"); }
.#{$iconPrefix}-bug-report { @include icon-override("bug_report"); }
.#{$iconPrefix}-build { @include icon-override("build"); }
.#{$iconPrefix}-burst-mode { @include icon-override("burst_mode"); }
.#{$iconPrefix}-business { @include icon-override("business"); }
.#{$iconPrefix}-business-center { @include icon-override("business_center"); }
.#{$iconPrefix}-cached { @include icon-override("cached"); }
.#{$iconPrefix}-cake { @include icon-override("cake"); }
.#{$iconPrefix}-call { @include icon-override("call"); }
.#{$iconPrefix}-call-end { @include icon-override("call_end"); }
.#{$iconPrefix}-call-made { @include icon-override("call_made"); }
.#{$iconPrefix}-call-merge { @include icon-override("call_merge"); }
.#{$iconPrefix}-call-missed { @include icon-override("call_missed"); }
.#{$iconPrefix}-call-missed-outgoing { @include icon-override("call_missed_outgoing"); }
.#{$iconPrefix}-call-received { @include icon-override("call_received"); }
.#{$iconPrefix}-call-split { @include icon-override("call_split"); }
.#{$iconPrefix}-call-to-action { @include icon-override("call_to_action"); }
.#{$iconPrefix}-camera { @include icon-override("camera"); }
.#{$iconPrefix}-camera-alt { @include icon-override("camera_alt"); }
.#{$iconPrefix}-camera-enhance { @include icon-override("camera_enhance"); }
.#{$iconPrefix}-camera-front { @include icon-override("camera_front"); }
.#{$iconPrefix}-camera-rear { @include icon-override("camera_rear"); }
.#{$iconPrefix}-camera-roll { @include icon-override("camera_roll"); }
.#{$iconPrefix}-cancel { @include icon-override("cancel"); }
.#{$iconPrefix}-card-giftcard { @include icon-override("card_giftcard"); }
.#{$iconPrefix}-card-membership { @include icon-override("card_membership"); }
.#{$iconPrefix}-card-travel { @include icon-override("card_travel"); }
.#{$iconPrefix}-casino { @include icon-override("casino"); }
.#{$iconPrefix}-cast { @include icon-override("cast"); }
.#{$iconPrefix}-cast-connected { @include icon-override("cast_connected"); }
.#{$iconPrefix}-center-focus-strong { @include icon-override("center_focus_strong"); }
.#{$iconPrefix}-center-focus-weak { @include icon-override("center_focus_weak"); }
.#{$iconPrefix}-change-history { @include icon-override("change_history"); }
.#{$iconPrefix}-chat { @include icon-override("chat"); }
.#{$iconPrefix}-chat-bubble { @include icon-override("chat_bubble"); }
.#{$iconPrefix}-chat-bubble-outline { @include icon-override("chat_bubble_outline"); }
.#{$iconPrefix}-check { @include icon-override("check"); }
.#{$iconPrefix}-check-box { @include icon-override("check_box"); }
.#{$iconPrefix}-check-box-outline-blank { @include icon-override("check_box_outline_blank"); }
.#{$iconPrefix}-check-circle { @include icon-override("check_circle"); }
.#{$iconPrefix}-chevron-left { @include icon-override("chevron_left"); }
.#{$iconPrefix}-chevron-right { @include icon-override("chevron_right"); }
.#{$iconPrefix}-child-care { @include icon-override("child_care"); }
.#{$iconPrefix}-child-friendly { @include icon-override("child_friendly"); }
.#{$iconPrefix}-chrome-reader-mode { @include icon-override("chrome_reader_mode"); }
.#{$iconPrefix}-class { @include icon-override("class"); }
.#{$iconPrefix}-clear { @include icon-override("clear"); }
.#{$iconPrefix}-clear-all { @include icon-override("clear_all"); }
.#{$iconPrefix}-close { @include icon-override("close"); }
.#{$iconPrefix}-closed-caption { @include icon-override("closed_caption"); }
.#{$iconPrefix}-cloud { @include icon-override("cloud"); }
.#{$iconPrefix}-cloud-circle { @include icon-override("cloud_circle"); }
.#{$iconPrefix}-cloud-done { @include icon-override("cloud_done"); }
.#{$iconPrefix}-cloud-download { @include icon-override("cloud_download"); }
.#{$iconPrefix}-cloud-off { @include icon-override("cloud_off"); }
.#{$iconPrefix}-cloud-queue { @include icon-override("cloud_queue"); }
.#{$iconPrefix}-cloud-upload { @include icon-override("cloud_upload"); }
.#{$iconPrefix}-code { @include icon-override("code"); }
.#{$iconPrefix}-collections { @include icon-override("collections"); }
.#{$iconPrefix}-collections-bookmark { @include icon-override("collections_bookmark"); }
.#{$iconPrefix}-color-lens { @include icon-override("color_lens"); }
.#{$iconPrefix}-colorize { @include icon-override("colorize"); }
.#{$iconPrefix}-comment { @include icon-override("comment"); }
.#{$iconPrefix}-compare { @include icon-override("compare"); }
.#{$iconPrefix}-compare-arrows { @include icon-override("compare_arrows"); }
.#{$iconPrefix}-computer { @include icon-override("computer"); }
.#{$iconPrefix}-confirmation-number { @include icon-override("confirmation_number"); }
.#{$iconPrefix}-contact-mail { @include icon-override("contact_mail"); }
.#{$iconPrefix}-contact-phone { @include icon-override("contact_phone"); }
.#{$iconPrefix}-contacts { @include icon-override("contacts"); }
.#{$iconPrefix}-content-copy { @include icon-override("content_copy"); }
.#{$iconPrefix}-content-cut { @include icon-override("content_cut"); }
.#{$iconPrefix}-content-paste { @include icon-override("content_paste"); }
.#{$iconPrefix}-control-point { @include icon-override("control_point"); }
.#{$iconPrefix}-control-point-duplicate { @include icon-override("control_point_duplicate"); }
.#{$iconPrefix}-copyright { @include icon-override("copyright"); }
.#{$iconPrefix}-create { @include icon-override("create"); }
.#{$iconPrefix}-create-new-folder { @include icon-override("create_new_folder"); }
.#{$iconPrefix}-credit-card { @include icon-override("credit_card"); }
.#{$iconPrefix}-crop { @include icon-override("crop"); }
.#{$iconPrefix}-crop-16-9 { @include icon-override("crop_16_9"); }
.#{$iconPrefix}-crop-3-2 { @include icon-override("crop_3_2"); }
.#{$iconPrefix}-crop-5-4 { @include icon-override("crop_5_4"); }
.#{$iconPrefix}-crop-7-5 { @include icon-override("crop_7_5"); }
.#{$iconPrefix}-crop-din { @include icon-override("crop_din"); }
.#{$iconPrefix}-crop-free { @include icon-override("crop_free"); }
.#{$iconPrefix}-crop-landscape { @include icon-override("crop_landscape"); }
.#{$iconPrefix}-crop-original { @include icon-override("crop_original"); }
.#{$iconPrefix}-crop-portrait { @include icon-override("crop_portrait"); }
.#{$iconPrefix}-crop-rotate { @include icon-override("crop_rotate"); }
.#{$iconPrefix}-crop-square { @include icon-override("crop_square"); }
.#{$iconPrefix}-dashboard { @include icon-override("dashboard"); }
.#{$iconPrefix}-data-usage { @include icon-override("data_usage"); }
.#{$iconPrefix}-date-range { @include icon-override("date_range"); }
.#{$iconPrefix}-dehaze { @include icon-override("dehaze"); }
.#{$iconPrefix}-delete { @include icon-override("delete"); }
.#{$iconPrefix}-delete-forever { @include icon-override("delete_forever"); }
.#{$iconPrefix}-delete-sweep { @include icon-override("delete_sweep"); }
.#{$iconPrefix}-description { @include icon-override("description"); }
.#{$iconPrefix}-desktop-mac { @include icon-override("desktop_mac"); }
.#{$iconPrefix}-desktop-windows { @include icon-override("desktop_windows"); }
.#{$iconPrefix}-details { @include icon-override("details"); }
.#{$iconPrefix}-developer-board { @include icon-override("developer_board"); }
.#{$iconPrefix}-developer-mode { @include icon-override("developer_mode"); }
.#{$iconPrefix}-device-hub { @include icon-override("device_hub"); }
.#{$iconPrefix}-devices { @include icon-override("devices"); }
.#{$iconPrefix}-devices-other { @include icon-override("devices_other"); }
.#{$iconPrefix}-dialer-sip { @include icon-override("dialer_sip"); }
.#{$iconPrefix}-dialpad { @include icon-override("dialpad"); }
.#{$iconPrefix}-directions { @include icon-override("directions"); }
.#{$iconPrefix}-directions-bike { @include icon-override("directions_bike"); }
.#{$iconPrefix}-directions-boat { @include icon-override("directions_boat"); }
.#{$iconPrefix}-directions-bus { @include icon-override("directions_bus"); }
.#{$iconPrefix}-directions-car { @include icon-override("directions_car"); }
.#{$iconPrefix}-directions-railway { @include icon-override("directions_railway"); }
.#{$iconPrefix}-directions-run { @include icon-override("directions_run"); }
.#{$iconPrefix}-directions-subway { @include icon-override("directions_subway"); }
.#{$iconPrefix}-directions-transit { @include icon-override("directions_transit"); }
.#{$iconPrefix}-directions-walk { @include icon-override("directions_walk"); }
.#{$iconPrefix}-disc-full { @include icon-override("disc_full"); }
.#{$iconPrefix}-dns { @include icon-override("dns"); }
.#{$iconPrefix}-do-not-disturb { @include icon-override("do_not_disturb"); }
.#{$iconPrefix}-do-not-disturb-alt { @include icon-override("do_not_disturb_alt"); }
.#{$iconPrefix}-do-not-disturb-off { @include icon-override("do_not_disturb_off"); }
.#{$iconPrefix}-do-not-disturb-on { @include icon-override("do_not_disturb_on"); }
.#{$iconPrefix}-dock { @include icon-override("dock"); }
.#{$iconPrefix}-domain { @include icon-override("domain"); }
.#{$iconPrefix}-done { @include icon-override("done"); }
.#{$iconPrefix}-done-all { @include icon-override("done_all"); }
.#{$iconPrefix}-donut-large { @include icon-override("donut_large"); }
.#{$iconPrefix}-donut-small { @include icon-override("donut_small"); }
.#{$iconPrefix}-drafts { @include icon-override("drafts"); }
.#{$iconPrefix}-drag-handle { @include icon-override("drag_handle"); }
.#{$iconPrefix}-drive-eta { @include icon-override("drive_eta"); }
.#{$iconPrefix}-dvr { @include icon-override("dvr"); }
.#{$iconPrefix}-edit { @include icon-override("edit"); }
.#{$iconPrefix}-edit-location { @include icon-override("edit_location"); }
.#{$iconPrefix}-eject { @include icon-override("eject"); }
.#{$iconPrefix}-email { @include icon-override("email"); }
.#{$iconPrefix}-enhanced-encryption { @include icon-override("enhanced_encryption"); }
.#{$iconPrefix}-equalizer { @include icon-override("equalizer"); }
.#{$iconPrefix}-error { @include icon-override("error"); }
.#{$iconPrefix}-error-outline { @include icon-override("error_outline"); }
.#{$iconPrefix}-euro-symbol { @include icon-override("euro_symbol"); }
.#{$iconPrefix}-ev-station { @include icon-override("ev_station"); }
.#{$iconPrefix}-event { @include icon-override("event"); }
.#{$iconPrefix}-event-available { @include icon-override("event_available"); }
.#{$iconPrefix}-event-busy { @include icon-override("event_busy"); }
.#{$iconPrefix}-event-note { @include icon-override("event_note"); }
.#{$iconPrefix}-event-seat { @include icon-override("event_seat"); }
.#{$iconPrefix}-exit-to-app { @include icon-override("exit_to_app"); }
.#{$iconPrefix}-expand-less { @include icon-override("expand_less"); }
.#{$iconPrefix}-expand-more { @include icon-override("expand_more"); }
.#{$iconPrefix}-explicit { @include icon-override("explicit"); }
.#{$iconPrefix}-explore { @include icon-override("explore"); }
.#{$iconPrefix}-exposure { @include icon-override("exposure"); }
.#{$iconPrefix}-exposure-neg-1 { @include icon-override("exposure_neg_1"); }
.#{$iconPrefix}-exposure-neg-2 { @include icon-override("exposure_neg_2"); }
.#{$iconPrefix}-exposure-plus-1 { @include icon-override("exposure_plus_1"); }
.#{$iconPrefix}-exposure-plus-2 { @include icon-override("exposure_plus_2"); }
.#{$iconPrefix}-exposure-zero { @include icon-override("exposure_zero"); }
.#{$iconPrefix}-extension { @include icon-override("extension"); }
.#{$iconPrefix}-face { @include icon-override("face"); }
.#{$iconPrefix}-fast-forward { @include icon-override("fast_forward"); }
.#{$iconPrefix}-fast-rewind { @include icon-override("fast_rewind"); }
.#{$iconPrefix}-favorite { @include icon-override("favorite"); }
.#{$iconPrefix}-favorite-border { @include icon-override("favorite_border"); }
.#{$iconPrefix}-featured-play-list { @include icon-override("featured_play_list"); }
.#{$iconPrefix}-featured-video { @include icon-override("featured_video"); }
.#{$iconPrefix}-feedback { @include icon-override("feedback"); }
.#{$iconPrefix}-fiber-dvr { @include icon-override("fiber_dvr"); }
.#{$iconPrefix}-fiber-manual-record { @include icon-override("fiber_manual_record"); }
.#{$iconPrefix}-fiber-new { @include icon-override("fiber_new"); }
.#{$iconPrefix}-fibern { @include icon-override("fiber_pin"); }
.#{$iconPrefix}-fiber-smart-record { @include icon-override("fiber_smart_record"); }
.#{$iconPrefix}-file-download { @include icon-override("file_download"); }
.#{$iconPrefix}-file-upload { @include icon-override("file_upload"); }
.#{$iconPrefix}-filter { @include icon-override("filter"); }
.#{$iconPrefix}-filter-1 { @include icon-override("filter_1"); }
.#{$iconPrefix}-filter-2 { @include icon-override("filter_2"); }
.#{$iconPrefix}-filter-3 { @include icon-override("filter_3"); }
.#{$iconPrefix}-filter-4 { @include icon-override("filter_4"); }
.#{$iconPrefix}-filter-5 { @include icon-override("filter_5"); }
.#{$iconPrefix}-filter-6 { @include icon-override("filter_6"); }
.#{$iconPrefix}-filter-7 { @include icon-override("filter_7"); }
.#{$iconPrefix}-filter-8 { @include icon-override("filter_8"); }
.#{$iconPrefix}-filter-9 { @include icon-override("filter_9"); }
.#{$iconPrefix}-filter-9-plus { @include icon-override("filter_9_plus"); }
.#{$iconPrefix}-filter-b-and-w { @include icon-override("filter_b_and_w"); }
.#{$iconPrefix}-filter-center-focus { @include icon-override("filter_center_focus"); }
.#{$iconPrefix}-filter-drama { @include icon-override("filter_drama"); }
.#{$iconPrefix}-filter-frames { @include icon-override("filter_frames"); }
.#{$iconPrefix}-filter-hdr { @include icon-override("filter_hdr"); }
.#{$iconPrefix}-filter-list { @include icon-override("filter_list"); }
.#{$iconPrefix}-filter-none { @include icon-override("filter_none"); }
.#{$iconPrefix}-filter-tilt-shift { @include icon-override("filter_tilt_shift"); }
.#{$iconPrefix}-filter-vintage { @include icon-override("filter_vintage"); }
.#{$iconPrefix}-find-in-page { @include icon-override("find_in_page"); }
.#{$iconPrefix}-find-replace { @include icon-override("find_replace"); }
.#{$iconPrefix}-fingerprint { @include icon-override("fingerprint"); }
.#{$iconPrefix}-first-page { @include icon-override("first_page"); }
.#{$iconPrefix}-fitness-center { @include icon-override("fitness_center"); }
.#{$iconPrefix}-flag { @include icon-override("flag"); }
.#{$iconPrefix}-flare { @include icon-override("flare"); }
.#{$iconPrefix}-flash-auto { @include icon-override("flash_auto"); }
.#{$iconPrefix}-flash-off { @include icon-override("flash_off"); }
.#{$iconPrefix}-flash-on { @include icon-override("flash_on"); }
.#{$iconPrefix}-flight { @include icon-override("flight"); }
.#{$iconPrefix}-flight-land { @include icon-override("flight_land"); }
.#{$iconPrefix}-flight-takeoff { @include icon-override("flight_takeoff"); }
.#{$iconPrefix}-flip { @include icon-override("flip"); }
.#{$iconPrefix}-flip-to-back { @include icon-override("flip_to_back"); }
.#{$iconPrefix}-flip-to-front { @include icon-override("flip_to_front"); }
.#{$iconPrefix}-folder { @include icon-override("folder"); }
.#{$iconPrefix}-folder-open { @include icon-override("folder_open"); }
.#{$iconPrefix}-folder-shared { @include icon-override("folder_shared"); }
.#{$iconPrefix}-folder-special { @include icon-override("folder_special"); }
.#{$iconPrefix}-font-download { @include icon-override("font_download"); }
.#{$iconPrefix}-format-align-center { @include icon-override("format_align_center"); }
.#{$iconPrefix}-format-align-justify { @include icon-override("format_align_justify"); }
.#{$iconPrefix}-format-align-left { @include icon-override("format_align_left"); }
.#{$iconPrefix}-format-align-right { @include icon-override("format_align_right"); }
.#{$iconPrefix}-format-bold { @include icon-override("format_bold"); }
.#{$iconPrefix}-format-clear { @include icon-override("format_clear"); }
.#{$iconPrefix}-format-color-fill { @include icon-override("format_color_fill"); }
.#{$iconPrefix}-format-color-reset { @include icon-override("format_color_reset"); }
.#{$iconPrefix}-format-color-text { @include icon-override("format_color_text"); }
.#{$iconPrefix}-format-indent-decrease { @include icon-override("format_indent_decrease"); }
.#{$iconPrefix}-format-indent-increase { @include icon-override("format_indent_increase"); }
.#{$iconPrefix}-format-italic { @include icon-override("format_italic"); }
.#{$iconPrefix}-format-line-spacing { @include icon-override("format_line_spacing"); }
.#{$iconPrefix}-format-list-bulleted { @include icon-override("format_list_bulleted"); }
.#{$iconPrefix}-format-list-numbered { @include icon-override("format_list_numbered"); }
.#{$iconPrefix}-format-paint { @include icon-override("format_paint"); }
.#{$iconPrefix}-format-quote { @include icon-override("format_quote"); }
.#{$iconPrefix}-format-shapes { @include icon-override("format_shapes"); }
.#{$iconPrefix}-format-size { @include icon-override("format_size"); }
.#{$iconPrefix}-format-strikethrough { @include icon-override("format_strikethrough"); }
.#{$iconPrefix}-format-textdirection-l-to-r { @include icon-override("format_textdirection_l_to_r"); }
.#{$iconPrefix}-format-textdirection-r-to-l { @include icon-override("format_textdirection_r_to_l"); }
.#{$iconPrefix}-format-underlined { @include icon-override("format_underlined"); }
.#{$iconPrefix}-forum { @include icon-override("forum"); }
.#{$iconPrefix}-forward { @include icon-override("forward"); }
.#{$iconPrefix}-forward-10 { @include icon-override("forward_10"); }
.#{$iconPrefix}-forward-30 { @include icon-override("forward_30"); }
.#{$iconPrefix}-forward-5 { @include icon-override("forward_5"); }
.#{$iconPrefix}-free-breakfast { @include icon-override("free_breakfast"); }
.#{$iconPrefix}-fullscreen { @include icon-override("fullscreen"); }
.#{$iconPrefix}-fullscreen-exit { @include icon-override("fullscreen_exit"); }
.#{$iconPrefix}-functions { @include icon-override("functions"); }
.#{$iconPrefix}-g-translate { @include icon-override("g_translate"); }
.#{$iconPrefix}-gamepad { @include icon-override("gamepad"); }
.#{$iconPrefix}-games { @include icon-override("games"); }
.#{$iconPrefix}-gavel { @include icon-override("gavel"); }
.#{$iconPrefix}-gesture { @include icon-override("gesture"); }
.#{$iconPrefix}-get-app { @include icon-override("get_app"); }
.#{$iconPrefix}-gif { @include icon-override("gif"); }
.#{$iconPrefix}-golf-course { @include icon-override("golf_course"); }
.#{$iconPrefix}-gps-fixed { @include icon-override("gps_fixed"); }
.#{$iconPrefix}-gps-not-fixed { @include icon-override("gps_not_fixed"); }
.#{$iconPrefix}-gps-off { @include icon-override("gps_off"); }
.#{$iconPrefix}-grade { @include icon-override("grade"); }
.#{$iconPrefix}-gradient { @include icon-override("gradient"); }
.#{$iconPrefix}-grain { @include icon-override("grain"); }
.#{$iconPrefix}-graphic-eq { @include icon-override("graphic_eq"); }
.#{$iconPrefix}-grid-off { @include icon-override("grid_off"); }
.#{$iconPrefix}-grid-on { @include icon-override("grid_on"); }
.#{$iconPrefix}-group { @include icon-override("group"); }
.#{$iconPrefix}-group-add { @include icon-override("group_add"); }
.#{$iconPrefix}-group-work { @include icon-override("group_work"); }
.#{$iconPrefix}-hd { @include icon-override("hd"); }
.#{$iconPrefix}-hdr-off { @include icon-override("hdr_off"); }
.#{$iconPrefix}-hdr-on { @include icon-override("hdr_on"); }
.#{$iconPrefix}-hdr-strong { @include icon-override("hdr_strong"); }
.#{$iconPrefix}-hdr-weak { @include icon-override("hdr_weak"); }
.#{$iconPrefix}-headset { @include icon-override("headset"); }
.#{$iconPrefix}-headset-mic { @include icon-override("headset_mic"); }
.#{$iconPrefix}-healing { @include icon-override("healing"); }
.#{$iconPrefix}-hearing { @include icon-override("hearing"); }
.#{$iconPrefix}-help { @include icon-override("help"); }
.#{$iconPrefix}-help-outline { @include icon-override("help_outline"); }
.#{$iconPrefix}-high-quality { @include icon-override("high_quality"); }
.#{$iconPrefix}-highlight { @include icon-override("highlight"); }
.#{$iconPrefix}-highlight-off { @include icon-override("highlight_off"); }
.#{$iconPrefix}-history { @include icon-override("history"); }
.#{$iconPrefix}-home { @include icon-override("home"); }
.#{$iconPrefix}-hot-tub { @include icon-override("hot_tub"); }
.#{$iconPrefix}-hotel { @include icon-override("hotel"); }
.#{$iconPrefix}-hourglass-empty { @include icon-override("hourglass_empty"); }
.#{$iconPrefix}-hourglass-full { @include icon-override("hourglass_full"); }
.#{$iconPrefix}-http { @include icon-override("http"); }
.#{$iconPrefix}-https { @include icon-override("https"); }
.#{$iconPrefix}-image { @include icon-override("image"); }
.#{$iconPrefix}-image-aspect-ratio { @include icon-override("image_aspect_ratio"); }
.#{$iconPrefix}-import-contacts { @include icon-override("import_contacts"); }
.#{$iconPrefix}-import-export { @include icon-override("import_export"); }
.#{$iconPrefix}-important-devices { @include icon-override("important_devices"); }
.#{$iconPrefix}-inbox { @include icon-override("inbox"); }
.#{$iconPrefix}-indeterminate-check-box { @include icon-override("indeterminate_check_box"); }
.#{$iconPrefix}-info { @include icon-override("info"); }
.#{$iconPrefix}-info-outline { @include icon-override("info_outline"); }
.#{$iconPrefix}-input { @include icon-override("input"); }
.#{$iconPrefix}-insert-chart { @include icon-override("insert_chart"); }
.#{$iconPrefix}-insert-comment { @include icon-override("insert_comment"); }
.#{$iconPrefix}-insert-drive-file { @include icon-override("insert_drive_file"); }
.#{$iconPrefix}-insert-emoticon { @include icon-override("insert_emoticon"); }
.#{$iconPrefix}-insert-invitation { @include icon-override("insert_invitation"); }
.#{$iconPrefix}-insert-link { @include icon-override("insert_link"); }
.#{$iconPrefix}-insert-photo { @include icon-override("insert_photo"); }
.#{$iconPrefix}-invert-colors { @include icon-override("invert_colors"); }
.#{$iconPrefix}-invert-colors-off { @include icon-override("invert_colors_off"); }
.#{$iconPrefix}-iso { @include icon-override("iso"); }
.#{$iconPrefix}-keyboard { @include icon-override("keyboard"); }
.#{$iconPrefix}-keyboard-arrow-down { @include icon-override("keyboard_arrow_down"); }
.#{$iconPrefix}-keyboard-arrow-left { @include icon-override("keyboard_arrow_left"); }
.#{$iconPrefix}-keyboard-arrow-right { @include icon-override("keyboard_arrow_right"); }
.#{$iconPrefix}-keyboard-arrow-up { @include icon-override("keyboard_arrow_up"); }
.#{$iconPrefix}-keyboard-backspace { @include icon-override("keyboard_backspace"); }
.#{$iconPrefix}-keyboard-capslock { @include icon-override("keyboard_capslock"); }
.#{$iconPrefix}-keyboard-hide { @include icon-override("keyboard_hide"); }
.#{$iconPrefix}-keyboard-return { @include icon-override("keyboard_return"); }
.#{$iconPrefix}-keyboard-tab { @include icon-override("keyboard_tab"); }
.#{$iconPrefix}-keyboard-voice { @include icon-override("keyboard_voice"); }
.#{$iconPrefix}-kitchen { @include icon-override("kitchen"); }
.#{$iconPrefix}-label { @include icon-override("label"); }
.#{$iconPrefix}-label-outline { @include icon-override("label_outline"); }
.#{$iconPrefix}-landscape { @include icon-override("landscape"); }
.#{$iconPrefix}-language { @include icon-override("language"); }
.#{$iconPrefix}-laptop { @include icon-override("laptop"); }
.#{$iconPrefix}-laptop-chromebook { @include icon-override("laptop_chromebook"); }
.#{$iconPrefix}-laptop-mac { @include icon-override("laptop_mac"); }
.#{$iconPrefix}-laptop-windows { @include icon-override("laptop_windows"); }
.#{$iconPrefix}-last-page { @include icon-override("last_page"); }
.#{$iconPrefix}-launch { @include icon-override("launch"); }
.#{$iconPrefix}-layers { @include icon-override("layers"); }
.#{$iconPrefix}-layers-clear { @include icon-override("layers_clear"); }
.#{$iconPrefix}-leak-add { @include icon-override("leak_add"); }
.#{$iconPrefix}-leak-remove { @include icon-override("leak_remove"); }
.#{$iconPrefix}-lens { @include icon-override("lens"); }
.#{$iconPrefix}-library-add { @include icon-override("library_add"); }
.#{$iconPrefix}-library-books { @include icon-override("library_books"); }
.#{$iconPrefix}-library-music { @include icon-override("library_music"); }
.#{$iconPrefix}-lightbulb-outline { @include icon-override("lightbulb_outline"); }
.#{$iconPrefix}-line-style { @include icon-override("line_style"); }
.#{$iconPrefix}-line-weight { @include icon-override("line_weight"); }
.#{$iconPrefix}-linear-scale { @include icon-override("linear_scale"); }
.#{$iconPrefix}-link { @include icon-override("link"); }
.#{$iconPrefix}-linked-camera { @include icon-override("linked_camera"); }
.#{$iconPrefix}-list { @include icon-override("list"); }
.#{$iconPrefix}-live-help { @include icon-override("live_help"); }
.#{$iconPrefix}-live-tv { @include icon-override("live_tv"); }
.#{$iconPrefix}-local-activity { @include icon-override("local_activity"); }
.#{$iconPrefix}-local-airport { @include icon-override("local_airport"); }
.#{$iconPrefix}-local-atm { @include icon-override("local_atm"); }
.#{$iconPrefix}-local-bar { @include icon-override("local_bar"); }
.#{$iconPrefix}-local-cafe { @include icon-override("local_cafe"); }
.#{$iconPrefix}-local-car-wash { @include icon-override("local_car_wash"); }
.#{$iconPrefix}-local-convenience-store { @include icon-override("local_convenience_store"); }
.#{$iconPrefix}-local-dining { @include icon-override("local_dining"); }
.#{$iconPrefix}-local-drink { @include icon-override("local_drink"); }
.#{$iconPrefix}-local-florist { @include icon-override("local_florist"); }
.#{$iconPrefix}-local-gas-station { @include icon-override("local_gas_station"); }
.#{$iconPrefix}-local-grocery-store { @include icon-override("local_grocery_store"); }
.#{$iconPrefix}-local-hospital { @include icon-override("local_hospital"); }
.#{$iconPrefix}-local-hotel { @include icon-override("local_hotel"); }
.#{$iconPrefix}-local-laundry-service { @include icon-override("local_laundry_service"); }
.#{$iconPrefix}-local-library { @include icon-override("local_library"); }
.#{$iconPrefix}-local-mall { @include icon-override("local_mall"); }
.#{$iconPrefix}-local-movies { @include icon-override("local_movies"); }
.#{$iconPrefix}-local-offer { @include icon-override("local_offer"); }
.#{$iconPrefix}-local-parking { @include icon-override("local_parking"); }
.#{$iconPrefix}-local-pharmacy { @include icon-override("local_pharmacy"); }
.#{$iconPrefix}-local-phone { @include icon-override("local_phone"); }
.#{$iconPrefix}-local-pizza { @include icon-override("local_pizza"); }
.#{$iconPrefix}-local-play { @include icon-override("local_play"); }
.#{$iconPrefix}-local-post-office { @include icon-override("local_post_office"); }
.#{$iconPrefix}-local-printshop { @include icon-override("local_printshop"); }
.#{$iconPrefix}-local-see { @include icon-override("local_see"); }
.#{$iconPrefix}-local-shipping { @include icon-override("local_shipping"); }
.#{$iconPrefix}-local-taxi { @include icon-override("local_taxi"); }
.#{$iconPrefix}-location-city { @include icon-override("location_city"); }
.#{$iconPrefix}-location-disabled { @include icon-override("location_disabled"); }
.#{$iconPrefix}-location-off { @include icon-override("location_off"); }
.#{$iconPrefix}-location-on { @include icon-override("location_on"); }
.#{$iconPrefix}-location-searching { @include icon-override("location_searching"); }
.#{$iconPrefix}-lock { @include icon-override("lock"); }
.#{$iconPrefix}-lock-open { @include icon-override("lock_open"); }
.#{$iconPrefix}-lock-outline { @include icon-override("lock_outline"); }
.#{$iconPrefix}-looks { @include icon-override("looks"); }
.#{$iconPrefix}-looks-3 { @include icon-override("looks_3"); }
.#{$iconPrefix}-looks-4 { @include icon-override("looks_4"); }
.#{$iconPrefix}-looks-5 { @include icon-override("looks_5"); }
.#{$iconPrefix}-looks-6 { @include icon-override("looks_6"); }
.#{$iconPrefix}-looks-one { @include icon-override("looks_one"); }
.#{$iconPrefix}-looks-two { @include icon-override("looks_two"); }
.#{$iconPrefix}-loop { @include icon-override("loop"); }
.#{$iconPrefix}-loupe { @include icon-override("loupe"); }
.#{$iconPrefix}-low-priority { @include icon-override("low_priority"); }
.#{$iconPrefix}-loyalty { @include icon-override("loyalty"); }
.#{$iconPrefix}-mail { @include icon-override("mail"); }
.#{$iconPrefix}-mail-outline { @include icon-override("mail_outline"); }
.#{$iconPrefix}-map { @include icon-override("map"); }
.#{$iconPrefix}-markunread { @include icon-override("markunread"); }
.#{$iconPrefix}-markunread-mailbox { @include icon-override("markunread_mailbox"); }
.#{$iconPrefix}-memory { @include icon-override("memory"); }
.#{$iconPrefix}-menu { @include icon-override("menu"); }
.#{$iconPrefix}-merge-type { @include icon-override("merge_type"); }
.#{$iconPrefix}-message { @include icon-override("message"); }
.#{$iconPrefix}-mic { @include icon-override("mic"); }
.#{$iconPrefix}-mic-none { @include icon-override("mic_none"); }
.#{$iconPrefix}-mic-off { @include icon-override("mic_off"); }
.#{$iconPrefix}-mms { @include icon-override("mms"); }
.#{$iconPrefix}-mode-comment { @include icon-override("mode_comment"); }
.#{$iconPrefix}-mode-edit { @include icon-override("mode_edit"); }
.#{$iconPrefix}-monetization-on { @include icon-override("monetization_on"); }
.#{$iconPrefix}-money-off { @include icon-override("money_off"); }
.#{$iconPrefix}-monochrome-photos { @include icon-override("monochrome_photos"); }
.#{$iconPrefix}-mood { @include icon-override("mood"); }
.#{$iconPrefix}-mood-bad { @include icon-override("mood_bad"); }
.#{$iconPrefix}-more { @include icon-override("more"); }
.#{$iconPrefix}-more-horiz { @include icon-override("more_horiz"); }
.#{$iconPrefix}-more-vert { @include icon-override("more_vert"); }
.#{$iconPrefix}-motorcycle { @include icon-override("motorcycle"); }
.#{$iconPrefix}-mouse { @include icon-override("mouse"); }
.#{$iconPrefix}-move-to-inbox { @include icon-override("move_to_inbox"); }
.#{$iconPrefix}-movie { @include icon-override("movie"); }
.#{$iconPrefix}-movie-creation { @include icon-override("movie_creation"); }
.#{$iconPrefix}-movie-filter { @include icon-override("movie_filter"); }
.#{$iconPrefix}-multiline-chart { @include icon-override("multiline_chart"); }
.#{$iconPrefix}-music-note { @include icon-override("music_note"); }
.#{$iconPrefix}-music-video { @include icon-override("music_video"); }
.#{$iconPrefix}-my-location { @include icon-override("my_location"); }
.#{$iconPrefix}-nature { @include icon-override("nature"); }
.#{$iconPrefix}-nature-people { @include icon-override("nature_people"); }
.#{$iconPrefix}-navigate-before { @include icon-override("navigate_before"); }
.#{$iconPrefix}-navigate-next { @include icon-override("navigate_next"); }
.#{$iconPrefix}-navigation { @include icon-override("navigation"); }
.#{$iconPrefix}-near-me { @include icon-override("near_me"); }
.#{$iconPrefix}-network-cell { @include icon-override("network_cell"); }
.#{$iconPrefix}-network-check { @include icon-override("network_check"); }
.#{$iconPrefix}-network-locked { @include icon-override("network_locked"); }
.#{$iconPrefix}-network-wifi { @include icon-override("network_wifi"); }
.#{$iconPrefix}-new-releases { @include icon-override("new_releases"); }
.#{$iconPrefix}-next-week { @include icon-override("next_week"); }
.#{$iconPrefix}-nfc { @include icon-override("nfc"); }
.#{$iconPrefix}-no-encryption { @include icon-override("no_encryption"); }
.#{$iconPrefix}-no-sim { @include icon-override("no_sim"); }
.#{$iconPrefix}-not-interested { @include icon-override("not_interested"); }
.#{$iconPrefix}-note { @include icon-override("note"); }
.#{$iconPrefix}-note-add { @include icon-override("note_add"); }
.#{$iconPrefix}-notifications { @include icon-override("notifications"); }
.#{$iconPrefix}-notifications-active { @include icon-override("notifications_active"); }
.#{$iconPrefix}-notifications-none { @include icon-override("notifications_none"); }
.#{$iconPrefix}-notifications-off { @include icon-override("notifications_off"); }
.#{$iconPrefix}-notifications-paused { @include icon-override("notifications_paused"); }
.#{$iconPrefix}-offline-pin { @include icon-override("offline_pin"); }
.#{$iconPrefix}-ondemand-video { @include icon-override("ondemand_video"); }
.#{$iconPrefix}-opacity { @include icon-override("opacity"); }
.#{$iconPrefix}-open-in-browser { @include icon-override("open_in_browser"); }
.#{$iconPrefix}-open-in-new { @include icon-override("open_in_new"); }
.#{$iconPrefix}-open-with { @include icon-override("open_with"); }
.#{$iconPrefix}-pages { @include icon-override("pages"); }
.#{$iconPrefix}-pageview { @include icon-override("pageview"); }
.#{$iconPrefix}-palette { @include icon-override("palette"); }
.#{$iconPrefix}-pan-tool { @include icon-override("pan_tool"); }
.#{$iconPrefix}-panorama { @include icon-override("panorama"); }
.#{$iconPrefix}-panorama-fish-eye { @include icon-override("panorama_fish_eye"); }
.#{$iconPrefix}-panorama-horizontal { @include icon-override("panorama_horizontal"); }
.#{$iconPrefix}-panorama-vertical { @include icon-override("panorama_vertical"); }
.#{$iconPrefix}-panorama-wide-angle { @include icon-override("panorama_wide_angle"); }
.#{$iconPrefix}-party-mode { @include icon-override("party_mode"); }
.#{$iconPrefix}-pause { @include icon-override("pause"); }
.#{$iconPrefix}-pause-circle-filled { @include icon-override("pause_circle_filled"); }
.#{$iconPrefix}-pause-circle-outline { @include icon-override("pause_circle_outline"); }
.#{$iconPrefix}-payment { @include icon-override("payment"); }
.#{$iconPrefix}-people { @include icon-override("people"); }
.#{$iconPrefix}-people-outline { @include icon-override("people_outline"); }
.#{$iconPrefix}-perm-camera-mic { @include icon-override("perm_camera_mic"); }
.#{$iconPrefix}-perm-contact-calendar { @include icon-override("perm_contact_calendar"); }
.#{$iconPrefix}-perm-data-setting { @include icon-override("perm_data_setting"); }
.#{$iconPrefix}-perm-device-information { @include icon-override("perm_device_information"); }
.#{$iconPrefix}-perm-identity { @include icon-override("perm_identity"); }
.#{$iconPrefix}-perm-media { @include icon-override("perm_media"); }
.#{$iconPrefix}-perm-phone-msg { @include icon-override("perm_phone_msg"); }
.#{$iconPrefix}-perm-scan-wifi { @include icon-override("perm_scan_wifi"); }
.#{$iconPrefix}-person { @include icon-override("person"); }
.#{$iconPrefix}-person-add { @include icon-override("person_add"); }
.#{$iconPrefix}-person-outline { @include icon-override("person_outline"); }
.#{$iconPrefix}-person-pin { @include icon-override("person_pin"); }
.#{$iconPrefix}-person-pin-circle { @include icon-override("person_pin_circle"); }
.#{$iconPrefix}-personal-video { @include icon-override("personal_video"); }
.#{$iconPrefix}-pets { @include icon-override("pets"); }
.#{$iconPrefix}-phone { @include icon-override("phone"); }
.#{$iconPrefix}-phone-android { @include icon-override("phone_android"); }
.#{$iconPrefix}-phone-bluetooth-speaker { @include icon-override("phone_bluetooth_speaker"); }
.#{$iconPrefix}-phone-forwarded { @include icon-override("phone_forwarded"); }
.#{$iconPrefix}-phone-in-talk { @include icon-override("phone_in_talk"); }
.#{$iconPrefix}-phone-iphone { @include icon-override("phone_iphone"); }
.#{$iconPrefix}-phone-locked { @include icon-override("phone_locked"); }
.#{$iconPrefix}-phone-missed { @include icon-override("phone_missed"); }
.#{$iconPrefix}-phone-paused { @include icon-override("phone_paused"); }
.#{$iconPrefix}-phonelink { @include icon-override("phonelink"); }
.#{$iconPrefix}-phonelink-erase { @include icon-override("phonelink_erase"); }
.#{$iconPrefix}-phonelink-lock { @include icon-override("phonelink_lock"); }
.#{$iconPrefix}-phonelink-off { @include icon-override("phonelink_off"); }
.#{$iconPrefix}-phonelink-ring { @include icon-override("phonelink_ring"); }
.#{$iconPrefix}-phonelink-setup { @include icon-override("phonelink_setup"); }
.#{$iconPrefix}-photo { @include icon-override("photo"); }
.#{$iconPrefix}-photo-album { @include icon-override("photo_album"); }
.#{$iconPrefix}-photo-camera { @include icon-override("photo_camera"); }
.#{$iconPrefix}-photo-filter { @include icon-override("photo_filter"); }
.#{$iconPrefix}-photo-library { @include icon-override("photo_library"); }
.#{$iconPrefix}-photo-size-select-actual { @include icon-override("photo_size_select_actual"); }
.#{$iconPrefix}-photo-size-select-large { @include icon-override("photo_size_select_large"); }
.#{$iconPrefix}-photo-size-select-small { @include icon-override("photo_size_select_small"); }
.#{$iconPrefix}-picture-as-pdf { @include icon-override("picture_as_pdf"); }
.#{$iconPrefix}-picture-in-picture { @include icon-override("picture_in_picture"); }
.#{$iconPrefix}-picture-in-picture-alt { @include icon-override("picture_in_picture_alt"); }
.#{$iconPrefix}-pie-chart { @include icon-override("pie_chart"); }
.#{$iconPrefix}-pie-chart-outlined { @include icon-override("pie_chart_outlined"); }
.#{$iconPrefix}-pin-drop { @include icon-override("pin_drop"); }
.#{$iconPrefix}-place { @include icon-override("place"); }
.#{$iconPrefix}-play-arrow { @include icon-override("play_arrow"); }
.#{$iconPrefix}-play-circle-filled { @include icon-override("play_circle_filled"); }
.#{$iconPrefix}-play-circle-outline { @include icon-override("play_circle_outline"); }
.#{$iconPrefix}-play-for-work { @include icon-override("play_for_work"); }
.#{$iconPrefix}-playlist-add { @include icon-override("playlist_add"); }
.#{$iconPrefix}-playlist-add-check { @include icon-override("playlist_add_check"); }
.#{$iconPrefix}-playlist-play { @include icon-override("playlist_play"); }
.#{$iconPrefix}-plus-one { @include icon-override("plus_one"); }
.#{$iconPrefix}-poll { @include icon-override("poll"); }
.#{$iconPrefix}-polymer { @include icon-override("polymer"); }
.#{$iconPrefix}-pool { @include icon-override("pool"); }
.#{$iconPrefix}-portable-wifi-off { @include icon-override("portable_wifi_off"); }
.#{$iconPrefix}-portrait { @include icon-override("portrait"); }
.#{$iconPrefix}-power { @include icon-override("power"); }
.#{$iconPrefix}-power-input { @include icon-override("power_input"); }
.#{$iconPrefix}-power-settings-new { @include icon-override("power_settings_new"); }
.#{$iconPrefix}-pregnant-woman { @include icon-override("pregnant_woman"); }
.#{$iconPrefix}-present-to-all { @include icon-override("present_to_all"); }
.#{$iconPrefix}-print { @include icon-override("print"); }
.#{$iconPrefix}-priority-high { @include icon-override("priority_high"); }
.#{$iconPrefix}-public { @include icon-override("public"); }
.#{$iconPrefix}-publish { @include icon-override("publish"); }
.#{$iconPrefix}-query-builder { @include icon-override("query_builder"); }
.#{$iconPrefix}-question-answer { @include icon-override("question_answer"); }
.#{$iconPrefix}-queue { @include icon-override("queue"); }
.#{$iconPrefix}-queue-music { @include icon-override("queue_music"); }
.#{$iconPrefix}-queue-play-next { @include icon-override("queue_play_next"); }
.#{$iconPrefix}-radio { @include icon-override("radio"); }
.#{$iconPrefix}-radio-button-checked { @include icon-override("radio_button_checked"); }
.#{$iconPrefix}-radio-button-unchecked { @include icon-override("radio_button_unchecked"); }
.#{$iconPrefix}-rate-review { @include icon-override("rate_review"); }
.#{$iconPrefix}-receipt { @include icon-override("receipt"); }
.#{$iconPrefix}-recent-actors { @include icon-override("recent_actors"); }
.#{$iconPrefix}-record-voice-over { @include icon-override("record_voice_over"); }
.#{$iconPrefix}-redeem { @include icon-override("redeem"); }
.#{$iconPrefix}-redo { @include icon-override("redo"); }
.#{$iconPrefix}-refresh { @include icon-override("refresh"); }
.#{$iconPrefix}-remove { @include icon-override("remove"); }
.#{$iconPrefix}-remove-circle { @include icon-override("remove_circle"); }
.#{$iconPrefix}-remove-circle-outline { @include icon-override("remove_circle_outline"); }
.#{$iconPrefix}-remove-from-queue { @include icon-override("remove_from_queue"); }
.#{$iconPrefix}-remove-red-eye { @include icon-override("remove_red_eye"); }
.#{$iconPrefix}-remove-shopping-cart { @include icon-override("remove_shopping_cart"); }
.#{$iconPrefix}-reorder { @include icon-override("reorder"); }
.#{$iconPrefix}-repeat { @include icon-override("repeat"); }
.#{$iconPrefix}-repeat-one { @include icon-override("repeat_one"); }
.#{$iconPrefix}-replay { @include icon-override("replay"); }
.#{$iconPrefix}-replay-10 { @include icon-override("replay_10"); }
.#{$iconPrefix}-replay-30 { @include icon-override("replay_30"); }
.#{$iconPrefix}-replay-5 { @include icon-override("replay_5"); }
.#{$iconPrefix}-reply { @include icon-override("reply"); }
.#{$iconPrefix}-reply-all { @include icon-override("reply_all"); }
.#{$iconPrefix}-report { @include icon-override("report"); }
.#{$iconPrefix}-report-problem { @include icon-override("report_problem"); }
.#{$iconPrefix}-restaurant { @include icon-override("restaurant"); }
.#{$iconPrefix}-restaurant-menu { @include icon-override("restaurant_menu"); }
.#{$iconPrefix}-restore { @include icon-override("restore"); }
.#{$iconPrefix}-restore-page { @include icon-override("restore_page"); }
.#{$iconPrefix}-ring-volume { @include icon-override("ring_volume"); }
.#{$iconPrefix}-room { @include icon-override("room"); }
.#{$iconPrefix}-room-service { @include icon-override("room_service"); }
.#{$iconPrefix}-rotate-90-degrees-ccw { @include icon-override("rotate_90_degrees_ccw"); }
.#{$iconPrefix}-rotate-left { @include icon-override("rotate_left"); }
.#{$iconPrefix}-rotate-right { @include icon-override("rotate_right"); }
.#{$iconPrefix}-rounded-corner { @include icon-override("rounded_corner"); }
.#{$iconPrefix}-router { @include icon-override("router"); }
.#{$iconPrefix}-rowing { @include icon-override("rowing"); }
.#{$iconPrefix}-rss-feed { @include icon-override("rss_feed"); }
.#{$iconPrefix}-rv-hookup { @include icon-override("rv_hookup"); }
.#{$iconPrefix}-satellite { @include icon-override("satellite"); }
.#{$iconPrefix}-save { @include icon-override("save"); }
.#{$iconPrefix}-scanner { @include icon-override("scanner"); }
.#{$iconPrefix}-schedule { @include icon-override("schedule"); }
.#{$iconPrefix}-school { @include icon-override("school"); }
.#{$iconPrefix}-screen-lock-landscape { @include icon-override("screen_lock_landscape"); }
.#{$iconPrefix}-screen-lock-portrait { @include icon-override("screen_lock_portrait"); }
.#{$iconPrefix}-screen-lock-rotation { @include icon-override("screen_lock_rotation"); }
.#{$iconPrefix}-screen-rotation { @include icon-override("screen_rotation"); }
.#{$iconPrefix}-screen-share { @include icon-override("screen_share"); }
.#{$iconPrefix}-sd-card { @include icon-override("sd_card"); }
.#{$iconPrefix}-sd-storage { @include icon-override("sd_storage"); }
.#{$iconPrefix}-search { @include icon-override("search"); }
.#{$iconPrefix}-security { @include icon-override("security"); }
.#{$iconPrefix}-select-all { @include icon-override("select_all"); }
.#{$iconPrefix}-send { @include icon-override("send"); }
.#{$iconPrefix}-sentiment-dissatisfied { @include icon-override("sentiment_dissatisfied"); }
.#{$iconPrefix}-sentiment-neutral { @include icon-override("sentiment_neutral"); }
.#{$iconPrefix}-sentiment-satisfied { @include icon-override("sentiment_satisfied"); }
.#{$iconPrefix}-sentiment-very-dissatisfied { @include icon-override("sentiment_very_dissatisfied"); }
.#{$iconPrefix}-sentiment-very-satisfied { @include icon-override("sentiment_very_satisfied"); }
.#{$iconPrefix}-settings { @include icon-override("settings"); }
.#{$iconPrefix}-settings-applications { @include icon-override("settings_applications"); }
.#{$iconPrefix}-settings-backup-restore { @include icon-override("settings_backup_restore"); }
.#{$iconPrefix}-settings-bluetooth { @include icon-override("settings_bluetooth"); }
.#{$iconPrefix}-settings-brightness { @include icon-override("settings_brightness"); }
.#{$iconPrefix}-settings-cell { @include icon-override("settings_cell"); }
.#{$iconPrefix}-settings-ethernet { @include icon-override("settings_ethernet"); }
.#{$iconPrefix}-settings-input-antenna { @include icon-override("settings_input_antenna"); }
.#{$iconPrefix}-settings-input-component { @include icon-override("settings_input_component"); }
.#{$iconPrefix}-settings-input-composite { @include icon-override("settings_input_composite"); }
.#{$iconPrefix}-settings-input-hdmi { @include icon-override("settings_input_hdmi"); }
.#{$iconPrefix}-settings-input-svideo { @include icon-override("settings_input_svideo"); }
.#{$iconPrefix}-settings-overscan { @include icon-override("settings_overscan"); }
.#{$iconPrefix}-settings-phone { @include icon-override("settings_phone"); }
.#{$iconPrefix}-settings-power { @include icon-override("settings_power"); }
.#{$iconPrefix}-settings-remote { @include icon-override("settings_remote"); }
.#{$iconPrefix}-settings-system-daydream { @include icon-override("settings_system_daydream"); }
.#{$iconPrefix}-settings-voice { @include icon-override("settings_voice"); }
.#{$iconPrefix}-share { @include icon-override("share"); }
.#{$iconPrefix}-shop { @include icon-override("shop"); }
.#{$iconPrefix}-shop-two { @include icon-override("shop_two"); }
.#{$iconPrefix}-shopping-basket { @include icon-override("shopping_basket"); }
.#{$iconPrefix}-shopping-cart { @include icon-override("shopping_cart"); }
.#{$iconPrefix}-short-text { @include icon-override("short_text"); }
.#{$iconPrefix}-show-chart { @include icon-override("show_chart"); }
.#{$iconPrefix}-shuffle { @include icon-override("shuffle"); }
.#{$iconPrefix}-signal-cellular-4-bar { @include icon-override("signal_cellular_4_bar"); }
.#{$iconPrefix}-signal-cellular-connected-no-internet-4-bar { @include icon-override("signal_cellular_connected_no_internet_4_bar"); }
.#{$iconPrefix}-signal-cellular-no-sim { @include icon-override("signal_cellular_no_sim"); }
.#{$iconPrefix}-signal-cellular-null { @include icon-override("signal_cellular_null"); }
.#{$iconPrefix}-signal-cellular-off { @include icon-override("signal_cellular_off"); }
.#{$iconPrefix}-signal-wifi-4-bar { @include icon-override("signal_wifi_4_bar"); }
.#{$iconPrefix}-signal-wifi-4-bar-lock { @include icon-override("signal_wifi_4_bar_lock"); }
.#{$iconPrefix}-signal-wifi-off { @include icon-override("signal_wifi_off"); }
.#{$iconPrefix}-sim-card { @include icon-override("sim_card"); }
.#{$iconPrefix}-sim-card-alert { @include icon-override("sim_card_alert"); }
.#{$iconPrefix}-skip-next { @include icon-override("skip_next"); }
.#{$iconPrefix}-skip-previous { @include icon-override("skip_previous"); }
.#{$iconPrefix}-slideshow { @include icon-override("slideshow"); }
.#{$iconPrefix}-slow-motion-video { @include icon-override("slow_motion_video"); }
.#{$iconPrefix}-smartphone { @include icon-override("smartphone"); }
.#{$iconPrefix}-smoke-free { @include icon-override("smoke_free"); }
.#{$iconPrefix}-smoking-rooms { @include icon-override("smoking_rooms"); }
.#{$iconPrefix}-sms { @include icon-override("sms"); }
.#{$iconPrefix}-sms-failed { @include icon-override("sms_failed"); }
.#{$iconPrefix}-snooze { @include icon-override("snooze"); }
.#{$iconPrefix}-sort { @include icon-override("sort"); }
.#{$iconPrefix}-sort-by-alpha { @include icon-override("sort_by_alpha"); }
.#{$iconPrefix}-spa { @include icon-override("spa"); }
.#{$iconPrefix}-space-bar { @include icon-override("space_bar"); }
.#{$iconPrefix}-speaker { @include icon-override("speaker"); }
.#{$iconPrefix}-speaker-group { @include icon-override("speaker_group"); }
.#{$iconPrefix}-speaker-notes { @include icon-override("speaker_notes"); }
.#{$iconPrefix}-speaker-notes-off { @include icon-override("speaker_notes_off"); }
.#{$iconPrefix}-speaker-phone { @include icon-override("speaker_phone"); }
.#{$iconPrefix}-spellcheck { @include icon-override("spellcheck"); }
.#{$iconPrefix}-star { @include icon-override("star"); }
.#{$iconPrefix}-star-border { @include icon-override("star_border"); }
.#{$iconPrefix}-star-half { @include icon-override("star_half"); }
.#{$iconPrefix}-stars { @include icon-override("stars"); }
.#{$iconPrefix}-stay-current-landscape { @include icon-override("stay_current_landscape"); }
.#{$iconPrefix}-stay-current-portrait { @include icon-override("stay_current_portrait"); }
.#{$iconPrefix}-stay-primary-landscape { @include icon-override("stay_primary_landscape"); }
.#{$iconPrefix}-stay-primary-portrait { @include icon-override("stay_primary_portrait"); }
.#{$iconPrefix}-stop { @include icon-override("stop"); }
.#{$iconPrefix}-stop-screen-share { @include icon-override("stop_screen_share"); }
.#{$iconPrefix}-storage { @include icon-override("storage"); }
.#{$iconPrefix}-store { @include icon-override("store"); }
.#{$iconPrefix}-store-mall-directory { @include icon-override("store_mall_directory"); }
.#{$iconPrefix}-straighten { @include icon-override("straighten"); }
.#{$iconPrefix}-streetview { @include icon-override("streetview"); }
.#{$iconPrefix}-strikethrough-s { @include icon-override("strikethrough_s"); }
.#{$iconPrefix}-style { @include icon-override("style"); }
.#{$iconPrefix}-subdirectory-arrow-left { @include icon-override("subdirectory_arrow_left"); }
.#{$iconPrefix}-subdirectory-arrow-right { @include icon-override("subdirectory_arrow_right"); }
.#{$iconPrefix}-subject { @include icon-override("subject"); }
.#{$iconPrefix}-subscriptions { @include icon-override("subscriptions"); }
.#{$iconPrefix}-subtitles { @include icon-override("subtitles"); }
.#{$iconPrefix}-subway { @include icon-override("subway"); }
.#{$iconPrefix}-supervisor-account { @include icon-override("supervisor_account"); }
.#{$iconPrefix}-surround-sound { @include icon-override("surround_sound"); }
.#{$iconPrefix}-swap-calls { @include icon-override("swap_calls"); }
.#{$iconPrefix}-swap-horiz { @include icon-override("swap_horiz"); }
.#{$iconPrefix}-swap-vert { @include icon-override("swap_vert"); }
.#{$iconPrefix}-swap-vertical-circle { @include icon-override("swap_vertical_circle"); }
.#{$iconPrefix}-switch-camera { @include icon-override("switch_camera"); }
.#{$iconPrefix}-switch-video { @include icon-override("switch_video"); }
.#{$iconPrefix}-sync { @include icon-override("sync"); }
.#{$iconPrefix}-sync-disabled { @include icon-override("sync_disabled"); }
.#{$iconPrefix}-sync-problem { @include icon-override("sync_problem"); }
.#{$iconPrefix}-system-update { @include icon-override("system_update"); }
.#{$iconPrefix}-system-update-alt { @include icon-override("system_update_alt"); }
.#{$iconPrefix}-tab { @include icon-override("tab"); }
.#{$iconPrefix}-tab-unselected { @include icon-override("tab_unselected"); }
.#{$iconPrefix}-tablet { @include icon-override("tablet"); }
.#{$iconPrefix}-tablet-android { @include icon-override("tablet_android"); }
.#{$iconPrefix}-tablet-mac { @include icon-override("tablet_mac"); }
.#{$iconPrefix}-tag-faces { @include icon-override("tag_faces"); }
.#{$iconPrefix}-tap-and-play { @include icon-override("tap_and_play"); }
.#{$iconPrefix}-terrain { @include icon-override("terrain"); }
.#{$iconPrefix}-text-fields { @include icon-override("text_fields"); }
.#{$iconPrefix}-text-format { @include icon-override("text_format"); }
.#{$iconPrefix}-textsms { @include icon-override("textsms"); }
.#{$iconPrefix}-texture { @include icon-override("texture"); }
.#{$iconPrefix}-theaters { @include icon-override("theaters"); }
.#{$iconPrefix}-thumb-down { @include icon-override("thumb_down"); }
.#{$iconPrefix}-thumb-up { @include icon-override("thumb_up"); }
.#{$iconPrefix}-thumbs-up-down { @include icon-override("thumbs_up_down"); }
.#{$iconPrefix}-time-to-leave { @include icon-override("time_to_leave"); }
.#{$iconPrefix}-timelapse { @include icon-override("timelapse"); }
.#{$iconPrefix}-timeline { @include icon-override("timeline"); }
.#{$iconPrefix}-timer { @include icon-override("timer"); }
.#{$iconPrefix}-timer-10 { @include icon-override("timer_10"); }
.#{$iconPrefix}-timer-3 { @include icon-override("timer_3"); }
.#{$iconPrefix}-timer-off { @include icon-override("timer_off"); }
.#{$iconPrefix}-title { @include icon-override("title"); }
.#{$iconPrefix}-toc { @include icon-override("toc"); }
.#{$iconPrefix}-today { @include icon-override("today"); }
.#{$iconPrefix}-toll { @include icon-override("toll"); }
.#{$iconPrefix}-tonality { @include icon-override("tonality"); }
.#{$iconPrefix}-touch-app { @include icon-override("touch_app"); }
.#{$iconPrefix}-toys { @include icon-override("toys"); }
.#{$iconPrefix}-track-changes { @include icon-override("track_changes"); }
.#{$iconPrefix}-traffic { @include icon-override("traffic"); }
.#{$iconPrefix}-train { @include icon-override("train"); }
.#{$iconPrefix}-tram { @include icon-override("tram"); }
.#{$iconPrefix}-transfer-within-a-station { @include icon-override("transfer_within_a_station"); }
.#{$iconPrefix}-transform { @include icon-override("transform"); }
.#{$iconPrefix}-translate { @include icon-override("translate"); }
.#{$iconPrefix}-trending-down { @include icon-override("trending_down"); }
.#{$iconPrefix}-trending-flat { @include icon-override("trending_flat"); }
.#{$iconPrefix}-trending-up { @include icon-override("trending_up"); }
.#{$iconPrefix}-tune { @include icon-override("tune"); }
.#{$iconPrefix}-turned-in { @include icon-override("turned_in"); }
.#{$iconPrefix}-turned-in-not { @include icon-override("turned_in_not"); }
.#{$iconPrefix}-tv { @include icon-override("tv"); }
.#{$iconPrefix}-unarchive { @include icon-override("unarchive"); }
.#{$iconPrefix}-undo { @include icon-override("undo"); }
.#{$iconPrefix}-unfold-less { @include icon-override("unfold_less"); }
.#{$iconPrefix}-unfold-more { @include icon-override("unfold_more"); }
.#{$iconPrefix}-update { @include icon-override("update"); }
.#{$iconPrefix}-usb { @include icon-override("usb"); }
.#{$iconPrefix}-verified-user { @include icon-override("verified_user"); }
.#{$iconPrefix}-vertical-align-bottom { @include icon-override("vertical_align_bottom"); }
.#{$iconPrefix}-vertical-align-center { @include icon-override("vertical_align_center"); }
.#{$iconPrefix}-vertical-align-top { @include icon-override("vertical_align_top"); }
.#{$iconPrefix}-vibration { @include icon-override("vibration"); }
.#{$iconPrefix}-video-call { @include icon-override("video_call"); }
.#{$iconPrefix}-video-label { @include icon-override("video_label"); }
.#{$iconPrefix}-video-library { @include icon-override("video_library"); }
.#{$iconPrefix}-videocam { @include icon-override("videocam"); }
.#{$iconPrefix}-videocam-off { @include icon-override("videocam_off"); }
.#{$iconPrefix}-videogame-asset { @include icon-override("videogame_asset"); }
.#{$iconPrefix}-view-agenda { @include icon-override("view_agenda"); }
.#{$iconPrefix}-view-array { @include icon-override("view_array"); }
.#{$iconPrefix}-view-carousel { @include icon-override("view_carousel"); }
.#{$iconPrefix}-view-column { @include icon-override("view_column"); }
.#{$iconPrefix}-view-comfy { @include icon-override("view_comfy"); }
.#{$iconPrefix}-view-compact { @include icon-override("view_compact"); }
.#{$iconPrefix}-view-day { @include icon-override("view_day"); }
.#{$iconPrefix}-view-headline { @include icon-override("view_headline"); }
.#{$iconPrefix}-view-list { @include icon-override("view_list"); }
.#{$iconPrefix}-view-module { @include icon-override("view_module"); }
.#{$iconPrefix}-view-quilt { @include icon-override("view_quilt"); }
.#{$iconPrefix}-view-stream { @include icon-override("view_stream"); }
.#{$iconPrefix}-view-week { @include icon-override("view_week"); }
.#{$iconPrefix}-vignette { @include icon-override("vignette"); }
.#{$iconPrefix}-visibility { @include icon-override("visibility"); }
.#{$iconPrefix}-visibility-off { @include icon-override("visibility_off"); }
.#{$iconPrefix}-voice-chat { @include icon-override("voice_chat"); }
.#{$iconPrefix}-voicemail { @include icon-override("voicemail"); }
.#{$iconPrefix}-volume-down { @include icon-override("volume_down"); }
.#{$iconPrefix}-volume-mute { @include icon-override("volume_mute"); }
.#{$iconPrefix}-volume-off { @include icon-override("volume_off"); }
.#{$iconPrefix}-volume-up { @include icon-override("volume_up"); }
.#{$iconPrefix}-vpn-key { @include icon-override("vpn_key"); }
.#{$iconPrefix}-vpn-lock { @include icon-override("vpn_lock"); }
.#{$iconPrefix}-wallpaper { @include icon-override("wallpaper"); }
.#{$iconPrefix}-warning { @include icon-override("warning"); }
.#{$iconPrefix}-watch { @include icon-override("watch"); }
.#{$iconPrefix}-watch-later { @include icon-override("watch_later"); }
.#{$iconPrefix}-wb-auto { @include icon-override("wb_auto"); }
.#{$iconPrefix}-wb-cloudy { @include icon-override("wb_cloudy"); }
.#{$iconPrefix}-wb-incandescent { @include icon-override("wb_incandescent"); }
.#{$iconPrefix}-wb-iridescent { @include icon-override("wb_iridescent"); }
.#{$iconPrefix}-wb-sunny { @include icon-override("wb_sunny"); }
.#{$iconPrefix}-wc { @include icon-override("wc"); }
.#{$iconPrefix}-web { @include icon-override("web"); }
.#{$iconPrefix}-web-asset { @include icon-override("web_asset"); }
.#{$iconPrefix}-weekend { @include icon-override("weekend"); }
.#{$iconPrefix}-whatshot { @include icon-override("whatshot"); }
.#{$iconPrefix}-widgets { @include icon-override("widgets"); }
.#{$iconPrefix}-wifi { @include icon-override("wifi"); }
.#{$iconPrefix}-wifi-lock { @include icon-override("wifi_lock"); }
.#{$iconPrefix}-wifi-tethering { @include icon-override("wifi_tethering"); }
.#{$iconPrefix}-work { @include icon-override("work"); }
.#{$iconPrefix}-wrap-text { @include icon-override("wrap_text"); }
.#{$iconPrefix}-youtube-searched-for { @include icon-override("youtube_searched_for"); }
.#{$iconPrefix}-zoom-in { @include icon-override("zoom_in"); }
.#{$iconPrefix}-zoom-out { @include icon-override("zoom_out"); }
.#{$iconPrefix}-zoom-out-map { @include icon-override("zoom_out_map"); }

.material-icons, .fa {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: $iconFontSize;
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}