/* Common */

$textColor:#212121!default;
$textSecondaryColor:#757575!default;
$fontSize:14px!default;
$fontFamily:"Roboto","Helvetica Neue",sans-serif!default;
$lineHeight:18px!default;
$transitionDuration:.3s!default;
$borderRadius:3px!default;
$inputOptionPadding:.625em .875em!default;
$hoverBgColor:#e8e8e8!default;
$hoverTextColor:#000000!default;
$activeMenuItemBadgeColor:#ffffff!default;
$activeMenuItemBadgeTextColor:#212121!default;
$dividerColor:#bdbdbd!default;
$letterSpacing:.25px!default;
$iconPrefix: 'pi-md'!default;
$overlayMaskBgColor:#424242!default;