body {
    .p-messages {

        ul {
            display: inline-block;
            margin-left: 0;
        }

        .p-messages-close {
            text-decoration: none;
            line-height: 1;
            right: 1em;
            @include border-radius(50%);
            @include transition(background-color $transitionDuration);
        }

        &.p-messages-info {
            background-color: $infoMessageBgColor;
            border-color: $infoMessageBgColor;
            color: $infoMessageTextColor;

            .p-messages-close {
                color: $infoMessageTextColor;
                &:hover {
                    background-color: lighten($infoMessageBgColor,15%);
                }
            }
        }

        &.p-messages-warn {
            background-color: $warnMessageBgColor;
            border-color: $warnMessageBgColor;
            color: $warnMessageTextColor;

            .p-messages-close, .p-messages-icon {
                color: $warnMessageTextColor;
                &:not(.p-messages-icon):hover {
                    background-color: lighten($warnMessageBgColor,15%);
                }
            }
        }

        &.p-messages-error {
            background-color: $errorMessageBgColor;
            border-color: $errorMessageBgColor;
            color: $errorMessageTextColor;

            .p-messages-close {
                color: $errorMessageTextColor;
                &:hover {
                    background-color: lighten($errorMessageBgColor,15%);
                }
            }
        }

        &.p-messages-success {
            background-color: $successMessageBgColor;
            border-color: $successMessageBgColor;
            color: $successMessageTextColor;

            .p-messages-close {
                color: $successMessageTextColor;
                &:hover {
                    background-color: lighten($successMessageBgColor,15%);
                }
            }
        }
    }

    .p-messages .p-messages-icon {
        background: none;
        color: #fff;

        &.pi-info-circle {
            @include material-icon("info");
            font-size: 1.75em;
        }

        &.pi-exclamation-triangle {
            @include material-icon("warning");
            font-size: 1.75em;
        }

        &.pi-times {
            @include material-icon("error_outline");
            font-size: 1.75em;
        }

        &.pi-check {
            @include material-icon("check_circle");
            font-size: 1.75em;
        }
    }

    .p-message {
        vertical-align: bottom;
        @include border-radius($borderRadius);

        .p-message-text {
            margin-left: 5px;
        }

        &.p-message-info {
            background-color: $infoMessageBgColor;
            border-color: $infoMessageBgColor;
            color: $infoMessageTextColor;

            .p-message-icon {
                color: $infoMessageTextColor;
            }
        }

        &.p-message-success {
            background-color: $successMessageBgColor;
            border-color: $successMessageBgColor;
            color: $successMessageTextColor;

            .p-message-icon {
                color: $successMessageTextColor;
            }
        }

        &.p-message-warn {
            background-color: $warnMessageBgColor;
            border-color: $warnMessageBgColor;
            color: $warnMessageTextColor;

            .p-message-icon {
                color: $warnMessageTextColor;
            }
        }

        &.p-message-error {
            background-color: $errorMessageBgColor;
            border-color: $errorMessageBgColor;
            color: $errorMessageTextColor;

            .p-message-icon {
                color: $errorMessageTextColor;
            }
        }
    }

    .p-message .p-message-icon {
        background: none;
        color: #fff;
        margin-top: -2px;

        &.pi-info-circle {
            @include material-icon("info");
            font-size: 1.286em;
        }

        &.pi-exclamation-triangle {
            @include material-icon("warning");
            font-size: 1.286em;
        }

        &.pi-times {
            @include material-icon("error_outline");
            font-size: 1.286em;
        }

        &.pi-check {
            @include material-icon("check_circle");
            font-size: 1.286em;
        }
    }

    .p-growl {
        top: 90px;

        .p-growl-item-container {
            opacity: 1;

            &.p-growl-message-info {
                background-color: $infoMessageBgColor;
                color: $infoMessageTextColor;

                .p-growl-icon-close-icon {
                    color: $infoMessageTextColor;
                }
            }

            &.p-growl-message-warn {
                background-color: $warnMessageBgColor;
                color: $warnMessageTextColor;

                .p-growl-icon-close-icon, .p-growl-message, .p-growl-image  {
                    color: $warnMessageTextColor;
                }
            }

            &.p-growl-message-error {
                background-color: $errorMessageBgColor;
                color: $errorMessageTextColor;

                .p-growl-icon-close-icon {
                    color: $errorMessageTextColor;
                }
            }

            &.p-growl-message-success {
                background-color: $successMessageBgColor;
                color: $successMessageTextColor;

                .p-growl-icon-close-icon {
                    color: $successMessageTextColor;
                }
            }

            &.p-shadow {
                @include overlay-content-shadow();
            }
        }

        .p-growl-item {
            .p-growl-image {
                background: none;
                color: #ffffff;
                font-size: 36px;

                &.pi-info-circle {
                    @include material-icon("info");
                    font-size: 1.75em;
                }

                &.pi-exclamation-triangle {
                    @include material-icon("warning");
                    font-size: 1.75em;
                }

                &.pi-times {
                    @include material-icon("error_outline");
                    font-size: 1.75em;
                }

                &.pi-check {
                    @include material-icon("check_circle");
                    font-size: 1.75em;
                }
            }

            .p-growl-message {
                color: #ffffff;
            }
        }
    }
}