html {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: $bodyBgColor;
    
    .ajax-loader {
        font-size: 32px;
        color: $accentColor;
    }
}

a {
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}