@import '../variables';

.addId-col,
.name-col,
.username-col {
    width: 200px;
}

.id-col {
    width: 150px;
}

.roles-col {
    width: 100px;
}

.search-field {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 20px 0;

    .p-inputtext {
        width: 200px;
    }
}

.p-datatable-header {
    padding: 0.625em 1em 0.3em 1em !important;
    height: 40px;

    .sync-progress {
        position: absolute;
        top: 13px;
        right: 70px;
    }

    .sync-button {
        position: absolute;
        top: 10px;
        right: 40px;
        font-size: 10px;
        //background-color: $primaryDarkColor;
    }

    .export-data-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 10px;
        //background-color: $primaryDarkColor;
    }
}
