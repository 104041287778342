@import '../serenity/sass/layout/variables';
@import './variables';

.table {
    .p-datatable-thead {
        tr {
            border-bottom: 1px solid #d8d8d8 !important;
        }
    }
}

.column {
    padding: 0.5em !important;

    .p-column-title {
        font-weight: bold !important;
    }
}

.edit-buttons-col {
    min-width: 75px;
    width: 75px;
    text-align: right;
}

.row-bg {
    background-color: $highlightTableRowColor !important;
}

.row-cursor {
    cursor: pointer;
}

.icon {
    &.active {
        color: $activeColor !important;
    }

    &.inactive {
        color: $inactiveColor !important;
    }
}
