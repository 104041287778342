@import '../variables';

.dialog {
    max-width: 375px;
    min-width: 300px;

    .p-dialog-footer {
        border-top: 1px solid #d8d8d8 !important;
    }

    .item-to-delete {
        padding: 1em 0;
        text-align: center;
        font-weight: bold;
    }

    .button {
        span {
            color: $primaryTextColor !important;
        }

        &.confirm {
            background-color: $activeColor !important;
        }

        &.cancel {
            background-color: $inactiveColor !important;
        }

        &.neutral {
            background-color: $primaryColor !important;
        }
    }
}
