@import '../variables';

$borderRadius: 4px;
$backgroundColor: #323232;
$messageColor: rgba(255, 255, 255, 0.7);

.custom-growl {
    &.p-growl {
        top: 90px;
        z-index: 9999;
        width: unset;
    }

    .p-growl-image {
        display: none !important;
    }

    .p-growl-item-container {
        max-width: 33vw;
        min-width: 344px;
        padding: 14px 16px;
        min-height: 48px;
        transform-origin: center;
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.14),
            0px 1px 18px 0px rgba(0, 0, 0, 0.12);
        pointer-events: auto;
        box-sizing: border-box;

        border-radius: $borderRadius;
        border-left: solid 15px;
        background-color: $backgroundColor !important;

        &.p-growl-message-info {
            border-left-color: $infoMessageColor;

            .p-growl-close {
                button {
                    span {
                        color: $infoMessageColor;
                    }
                }
            }
        }

        &.p-growl-message-warn {
            border-left-color: $warnMessageColor;

            .p-growl-close {
                button {
                    span {
                        color: $warnMessageColor;
                    }
                }
            }
        }

        &.p-growl-message-error {
            border-left-color: $errorMessageColor;

            .p-growl-close {
                button {
                    span {
                        color: $errorMessageColor;
                    }
                }
            }
        }

        &.p-growl-message-success {
            border-left-color: $successMessageColor;

            .p-growl-close {
                button {
                    span {
                        color: $successMessageColor;
                    }
                }
            }
        }

        .p-growl-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: unset;
            margin: unset;
            line-height: 20px;
            opacity: 1;
            font-size: 14px;

            .p-growl-message {
                order: 1;
                padding: unset;
                margin: unset;
                height: 100%;
                line-height: 20px;
                opacity: 1;
                color: $messageColor !important;
            }

            .p-growl-close {
                order: 2;

                flex-shrink: 0;
                margin: -8px -8px -8px 8px;
                line-height: 1;
                font-weight: 500;
            }

            .p-growl-icon-close-icon {
                display: none !important;
            }

            button {
                position: relative;
                margin: 0;
                padding: 0 16px;
                max-height: 36px;
                min-width: 0;
                box-sizing: border-box;

                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                cursor: pointer;
                outline: 0;
                border: none;
                -webkit-tap-highlight-color: transparent;
                display: inline-block;
                white-space: nowrap;
                text-decoration: none;
                vertical-align: baseline;
                text-align: center;
                line-height: 36px;
                overflow: visible;
                background: transparent;

                span {
                    text-align: center;
                    white-space: nowrap;
                    user-select: none;
                    cursor: pointer;
                    font-weight: 400;
                    color: $messageColor;
                }

                &::before {
                    display: none !important;
                }
            }
        }
    }
}
