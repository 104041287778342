.title-col,
.ip-col {
    width: 200px;
}

.roles-col {
    width: 100px;
}

.active-col {
    width: 50px;
}
