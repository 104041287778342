@import '../../serenity/sass/layout/variables';
@import '../variables';

.edit-button {
    &.edit {
        border-radius: 3px !important;

        /*@media (max-width: $mobileBreakpoint) {
            width: 2.5em !important;
            height: 2.5em !important;
            padding: 0 14px !important;

            > .p-button-text {
                display: none !important;
            }

            > .p-button-icon-left {
                left: 0.4em !important;
            }
        }*/
    }

    &.delete {
        background-color: $inactiveColor !important;
        color: #ffffff !important;
        border-radius: 3px !important;

        .p-button-icon-left {
            color: #ffffff !important;
        }
    }
}
