@import './variables';

//$errorMessageColor: #e62a10;

.form-input {
    width: 100% !important;

    input {
        width: 100% !important;
    }
}

.form-tooltip {
    font-size: smaller;
}

.error-message {
    color: $errorMessageColor;
    font-size: smaller;
}

.dropdown-label-float {
    position: relative;
    top: -42px;
    left: 5px;
    height: 5px;
    //width: 0;
    display: block;
    color: #607d8b;
    font-size: 12px;
    font-weight: normal;
    pointer-events: none;
}

.chips-label {
    position: relative;
    top: 8px;
    left: 5px;
    height: 0;

    font-size: 14px;
    font-weight: normal;
    color: #999;
    pointer-events: none;
    display: block;

    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;

    &.float {
        top: -18px;
        font-size: 12px;
        color: #607d8b;
    }
}

.multiselect-error {
    &.p-multiselect {
        border-bottom: $errorMessageColor 1px solid;
    }
}

.textarea-label {
    position: relative;
    //top: 8px;
    left: 5px;
    height: 0;

    font-size: 14px;
    font-weight: normal;
    color: #999;
    pointer-events: none;
    display: block;

    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;

    &.float {
        top: -18px;
        font-size: 12px;
        color: #607d8b;
    }
}

.input-textarea {
    min-height: 21px;
    //max-height: 200px;
}

.accordion {
    .p-accordion-tab:not(:last-child) {
        padding-bottom: 14px;
    }
}
